import { h } from 'preact';
export default function RenderMetaFields(props) {
  var computedMetaFields = props.computedMetaFields,
    requiredMetaFields = props.requiredMetaFields,
    updateMeta = props.updateMeta,
    form = props.form,
    formState = props.formState;
  var fieldCSSClasses = {
    text: 'uppy-u-reset uppy-c-textInput uppy-Dashboard-FileCard-input'
  };
  return computedMetaFields.map(function (field) {
    var id = "uppy-Dashboard-FileCard-input-".concat(field.id);
    var required = requiredMetaFields.includes(field.id);
    return h("fieldset", {
      key: field.id,
      className: "uppy-Dashboard-FileCard-fieldset"
    }, h("label", {
      className: "uppy-Dashboard-FileCard-label",
      htmlFor: id
    }, field.name), field.render !== undefined ? field.render({
      value: formState[field.id],
      onChange: function onChange(newVal) {
        return updateMeta(newVal, field.id);
      },
      fieldCSSClasses: fieldCSSClasses,
      required: required,
      form: form.id
    }, h) : h("input", {
      className: fieldCSSClasses.text,
      id: id,
      form: form.id,
      type: field.type || 'text',
      required: required,
      value: formState[field.id],
      placeholder: field.placeholder,
      onInput: function onInput(ev) {
        return updateMeta(ev.target.value, field.id);
      },
      "data-uppy-super-focusable": true
    }));
  });
}
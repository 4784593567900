import { h } from 'preact';
import classNames from 'classnames';
import ignoreEvent from '../utils/ignoreEvent.js';
function PickerPanelContent(_ref) {
  var activePickerPanel = _ref.activePickerPanel,
    className = _ref.className,
    hideAllPanels = _ref.hideAllPanels,
    i18n = _ref.i18n,
    state = _ref.state,
    uppy = _ref.uppy;
  return h("div", {
    className: classNames('uppy-DashboardContent-panel', className),
    role: "tabpanel",
    "data-uppy-panelType": "PickerPanel",
    id: "uppy-DashboardContent-panel--".concat(activePickerPanel.id),
    onDragOver: ignoreEvent,
    onDragLeave: ignoreEvent,
    onDrop: ignoreEvent,
    onPaste: ignoreEvent
  }, h("div", {
    className: "uppy-DashboardContent-bar"
  }, h("div", {
    className: "uppy-DashboardContent-title",
    role: "heading",
    "aria-level": "1"
  }, i18n('importFrom', {
    name: activePickerPanel.name
  })), h("button", {
    className: "uppy-DashboardContent-back",
    type: "button",
    onClick: hideAllPanels
  }, i18n('cancel'))), h("div", {
    className: "uppy-DashboardContent-panelBody"
  }, uppy.getPlugin(activePickerPanel.id).render(state)));
}
export default PickerPanelContent;
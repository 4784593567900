function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { h, Fragment } from 'preact';
import { useEffect, useState, useCallback } from 'preact/hooks';
import { nanoid } from 'nanoid/non-secure';
// import debounce from 'lodash.debounce'

export default function SearchFilterInput(props) {
  var search = props.search,
    searchOnInput = props.searchOnInput,
    searchTerm = props.searchTerm,
    showButton = props.showButton,
    inputLabel = props.inputLabel,
    clearSearchLabel = props.clearSearchLabel,
    buttonLabel = props.buttonLabel,
    clearSearch = props.clearSearch,
    inputClassName = props.inputClassName,
    buttonCSSClassName = props.buttonCSSClassName;
  var _useState = useState(searchTerm != null ? searchTerm : ''),
    _useState2 = _slicedToArray(_useState, 2),
    searchText = _useState2[0],
    setSearchText = _useState2[1];
  // const debouncedSearch = debounce((q) => search(q), 1000)

  var validateAndSearch = useCallback(function (ev) {
    ev.preventDefault();
    search(searchText);
  }, [search, searchText]);
  var handleInput = useCallback(function (ev) {
    var inputValue = ev.target.value;
    setSearchText(inputValue);
    if (searchOnInput) search(inputValue);
  }, [setSearchText, searchOnInput, search]);
  var handleReset = function handleReset() {
    setSearchText('');
    if (clearSearch) clearSearch();
  };
  var _useState3 = useState(function () {
      var formEl = document.createElement('form');
      formEl.setAttribute('tabindex', '-1');
      formEl.id = nanoid();
      return formEl;
    }),
    _useState4 = _slicedToArray(_useState3, 1),
    form = _useState4[0];
  useEffect(function () {
    document.body.appendChild(form);
    form.addEventListener('submit', validateAndSearch);
    return function () {
      form.removeEventListener('submit', validateAndSearch);
      document.body.removeChild(form);
    };
  }, [form, validateAndSearch]);
  return h(Fragment, null, h("input", {
    className: "uppy-u-reset ".concat(inputClassName),
    type: "search",
    "aria-label": inputLabel,
    placeholder: inputLabel,
    value: searchText,
    onInput: handleInput,
    form: form.id,
    "data-uppy-super-focusable": true
  }), !showButton && h("svg", {
    "aria-hidden": "true",
    focusable: "false",
    class: "uppy-c-icon uppy-ProviderBrowser-searchFilterIcon",
    width: "12",
    height: "12",
    viewBox: "0 0 12 12"
  }, h("path", {
    d: "M8.638 7.99l3.172 3.172a.492.492 0 1 1-.697.697L7.91 8.656a4.977 4.977 0 0 1-2.983.983C2.206 9.639 0 7.481 0 4.819 0 2.158 2.206 0 4.927 0c2.721 0 4.927 2.158 4.927 4.82a4.74 4.74 0 0 1-1.216 3.17zm-3.71.685c2.176 0 3.94-1.726 3.94-3.856 0-2.129-1.764-3.855-3.94-3.855C2.75.964.984 2.69.984 4.819c0 2.13 1.765 3.856 3.942 3.856z"
  })), !showButton && searchText && h("button", {
    className: "uppy-u-reset uppy-ProviderBrowser-searchFilterReset",
    type: "button",
    "aria-label": clearSearchLabel,
    title: clearSearchLabel,
    onClick: handleReset
  }, h("svg", {
    "aria-hidden": "true",
    focusable: "false",
    className: "uppy-c-icon",
    viewBox: "0 0 19 19"
  }, h("path", {
    d: "M17.318 17.232L9.94 9.854 9.586 9.5l-.354.354-7.378 7.378h.707l-.62-.62v.706L9.318 9.94l.354-.354-.354-.354L1.94 1.854v.707l.62-.62h-.706l7.378 7.378.354.354.354-.354 7.378-7.378h-.707l.622.62v-.706L9.854 9.232l-.354.354.354.354 7.378 7.378.708-.707-7.38-7.378v.708l7.38-7.38.353-.353-.353-.353-.622-.622-.353-.353-.354.352-7.378 7.38h.708L2.56 1.23 2.208.88l-.353.353-.622.62-.353.355.352.353 7.38 7.38v-.708l-7.38 7.38-.353.353.352.353.622.622.353.353.354-.353 7.38-7.38h-.708l7.38 7.38z"
  }))), showButton && h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-c-btn-primary ".concat(buttonCSSClassName),
    type: "submit",
    form: form.id
  }, buttonLabel));
}
export function filterNonFailedFiles(files) {
  var hasError = function hasError(file) {
    return 'error' in file && !!file.error;
  };
  return files.filter(function (file) {
    return !hasError(file);
  });
}

// Don't double-emit upload-started for Golden Retriever-restored files that were already started
export function filterFilesToEmitUploadStarted(files) {
  return files.filter(function (file) {
    var _file$progress;
    return !((_file$progress = file.progress) != null && _file$progress.uploadStarted) || !file.isRestored;
  });
}
import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import VirtualList from '@uppy/utils/lib/VirtualList';
import FileItem from "./FileItem/index.js";
function chunks(list, size) {
  var chunked = [];
  var currentChunk = [];
  list.forEach(function (item) {
    if (currentChunk.length < size) {
      currentChunk.push(item);
    } else {
      chunked.push(currentChunk);
      currentChunk = [item];
    }
  });
  if (currentChunk.length) chunked.push(currentChunk);
  return chunked;
}
export default (function (_ref) {
  var id = _ref.id,
    error = _ref.error,
    i18n = _ref.i18n,
    uppy = _ref.uppy,
    files = _ref.files,
    acquirers = _ref.acquirers,
    resumableUploads = _ref.resumableUploads,
    hideRetryButton = _ref.hideRetryButton,
    hidePauseResumeButton = _ref.hidePauseResumeButton,
    hideCancelButton = _ref.hideCancelButton,
    showLinkToFileUploadResult = _ref.showLinkToFileUploadResult,
    showRemoveButtonAfterComplete = _ref.showRemoveButtonAfterComplete,
    isWide = _ref.isWide,
    metaFields = _ref.metaFields,
    isSingleFile = _ref.isSingleFile,
    toggleFileCard = _ref.toggleFileCard,
    handleRequestThumbnail = _ref.handleRequestThumbnail,
    handleCancelThumbnail = _ref.handleCancelThumbnail,
    recoveredState = _ref.recoveredState,
    individualCancellation = _ref.individualCancellation,
    itemsPerRow = _ref.itemsPerRow,
    openFileEditor = _ref.openFileEditor,
    canEditFile = _ref.canEditFile,
    toggleAddFilesPanel = _ref.toggleAddFilesPanel,
    containerWidth = _ref.containerWidth,
    containerHeight = _ref.containerHeight;
  // It's not great that this is hardcoded!
  // It's ESPECIALLY not great that this is checking against `itemsPerRow`!
  var rowHeight = itemsPerRow === 1
  // Mobile
  ? 71
  // 190px height + 2 * 5px margin
  : 200;

  // Sort files by file.isGhost, ghost files first, only if recoveredState is present
  var rows = useMemo(function () {
    var sortByGhostComesFirst = function sortByGhostComesFirst(file1, file2) {
      return files[file2].isGhost - files[file1].isGhost;
    };
    var fileIds = Object.keys(files);
    if (recoveredState) fileIds.sort(sortByGhostComesFirst);
    return chunks(fileIds, itemsPerRow);
  }, [files, itemsPerRow, recoveredState]);
  var renderRow = function renderRow(row) {
    return (
      // The `role="presentation` attribute ensures that the list items are properly
      // associated with the `VirtualList` element.
      // We use the first file ID as the key—this should not change across scroll rerenders
      h("div", {
        class: "uppy-Dashboard-filesInner",
        role: "presentation",
        key: row[0]
      }, row.map(function (fileID) {
        return h(FileItem, {
          key: fileID,
          uppy: uppy
          // FIXME This is confusing, it's actually the Dashboard's plugin ID
          ,

          id: id,
          error: error
          // TODO move this to context
          ,

          i18n: i18n
          // features
          ,

          acquirers: acquirers,
          resumableUploads: resumableUploads,
          individualCancellation: individualCancellation
          // visual options
          ,

          hideRetryButton: hideRetryButton,
          hidePauseResumeButton: hidePauseResumeButton,
          hideCancelButton: hideCancelButton,
          showLinkToFileUploadResult: showLinkToFileUploadResult,
          showRemoveButtonAfterComplete: showRemoveButtonAfterComplete,
          isWide: isWide,
          metaFields: metaFields,
          recoveredState: recoveredState,
          isSingleFile: isSingleFile,
          containerWidth: containerWidth,
          containerHeight: containerHeight
          // callbacks
          ,

          toggleFileCard: toggleFileCard,
          handleRequestThumbnail: handleRequestThumbnail,
          handleCancelThumbnail: handleCancelThumbnail,
          role: "listitem",
          openFileEditor: openFileEditor,
          canEditFile: canEditFile,
          toggleAddFilesPanel: toggleAddFilesPanel,
          file: files[fileID]
        });
      }))
    );
  };
  if (isSingleFile) {
    return h("div", {
      class: "uppy-Dashboard-files"
    }, renderRow(rows[0]));
  }
  return h(VirtualList, {
    class: "uppy-Dashboard-files",
    role: "list",
    data: rows,
    renderRow: renderRow,
    rowHeight: rowHeight
  });
});
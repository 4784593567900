import throttle from 'lodash/throttle.js';
function emitSocketProgress(uploader, progressData, file) {
  var progress = progressData.progress,
    bytesUploaded = progressData.bytesUploaded,
    bytesTotal = progressData.bytesTotal;
  if (progress) {
    uploader.uppy.log("Upload progress: ".concat(progress));
    uploader.uppy.emit('upload-progress', file, {
      uploader: uploader,
      bytesUploaded: bytesUploaded,
      bytesTotal: bytesTotal
    });
  }
}
export default throttle(emitSocketProgress, 300, {
  leading: true,
  trailing: true
});
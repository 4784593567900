import { h } from 'preact';
export default (function (props) {
  var icon = props.icon,
    hasAudio = props.hasAudio,
    i18n = props.i18n;
  return h("div", {
    className: "uppy-Audio-permissons"
  }, h("div", {
    className: "uppy-Audio-permissonsIcon"
  }, icon()), h("h1", {
    className: "uppy-Audio-title"
  }, hasAudio ? i18n('allowAudioAccessTitle') : i18n('noAudioTitle')), h("p", null, hasAudio ? i18n('allowAudioAccessDescription') : i18n('noAudioDescription')));
});
/**
 * Adds zero to strings shorter than two characters.
 */
function pad(number) {
  return number < 10 ? "0".concat(number) : number.toString();
}

/**
 * Returns a timestamp in the format of `hours:minutes:seconds`
 */
export default function getTimeStamp() {
  var date = new Date();
  var hours = pad(date.getHours());
  var minutes = pad(date.getMinutes());
  var seconds = pad(date.getSeconds());
  return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
}
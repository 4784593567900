function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import { h } from 'preact';
import classNames from 'classnames';
import ItemIcon from "./components/ItemIcon.js";
import GridListItem from "./components/GridLi.js";
import ListItem from "./components/ListLi.js";
export default (function (props) {
  var author = props.author,
    getItemIcon = props.getItemIcon,
    isChecked = props.isChecked,
    isDisabled = props.isDisabled,
    viewType = props.viewType;
  var itemIconString = getItemIcon();
  var className = classNames('uppy-ProviderBrowserItem', {
    'uppy-ProviderBrowserItem--selected': isChecked
  }, {
    'uppy-ProviderBrowserItem--disabled': isDisabled
  }, {
    'uppy-ProviderBrowserItem--noPreview': itemIconString === 'video'
  });
  var itemIconEl = h(ItemIcon, {
    itemIconString: itemIconString
  });
  switch (viewType) {
    case 'grid':
      return h(GridListItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      , _extends({}, props, {
        className: className,
        itemIconEl: itemIconEl
      }));
    case 'list':
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        h(ListItem, _extends({}, props, {
          className: className,
          itemIconEl: itemIconEl
        }))
      );
    case 'unsplash':
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        h(GridListItem, _extends({}, props, {
          className: className,
          itemIconEl: itemIconEl
        }), h("a", {
          href: "".concat(author.url, "?utm_source=Companion&utm_medium=referral"),
          target: "_blank",
          rel: "noopener noreferrer",
          className: "uppy-ProviderBrowserItem-author",
          tabIndex: "-1"
        }, author.name))
      );
    default:
      throw new Error("There is no such type ".concat(viewType));
  }
});